import { render, staticRenderFns } from "./TraditionalImplantOperation.vue?vue&type=template&id=955ed33c&scoped=true"
import script from "./TraditionalImplantOperation.vue?vue&type=script&lang=js"
export * from "./TraditionalImplantOperation.vue?vue&type=script&lang=js"
import style0 from "./TraditionalImplantOperation.vue?vue&type=style&index=0&id=955ed33c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "955ed33c",
  null
  
)

/* custom blocks */
import block0 from "@/locales/traditional_implant_operation.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Ftraditional-dental-implant%2Foperation%2FTraditionalImplantOperation.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports